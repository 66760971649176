import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { table_data } from "./TableData";
import "./Table.css";
import StatusBar from "../StatusBar/StatusBar";
import TripleDotDropDown from "../TripleDotDropDown";
import { Modal } from "@material-ui/core";
import axios from "axios";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import Swal from "sweetalert2";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DataTableExtensions from "react-data-table-component-extensions";
import moment from "moment";
import { DropdownButton } from "react-bootstrap";
import { client_approve_lead, client_reject_lead } from "../../utils";
createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#f2f2f2",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#fafafa",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
function Table({ api, admin, set_check, check }) {
  const [open1, setOpen1] = useState(false);
  const [images, setImage] = useState([]);
  const [formData, setFormData] = useState({ remark: "" });
  const [lead_id, set_lead_id] = useState("");

  // const createNotification = (type) => {
  //   return () => {
  //     switch (type) {
  //       case "info":
  //         NotificationManager.info("Info message");
  //         break;
  //       case "success":
  //         NotificationManager.success(
  //           "Success message",
  //           "successfully uploaded status"
  //         );
  //         break;
  //       case "warning":
  //         NotificationManager.warning(
  //           "Warning message",
  //           "Close after 3000ms",
  //           3000
  //         );
  //         break;
  //       case "error":
  //         NotificationManager.error("Error message", "Click me!", 5000, () => {
  //           alert("callback");
  //         });
  //         break;
  //     }
  //   };
  // };

  // const uploaderHandel = (e) => {
  //   const data = e.target.id.split(",");
  //   const dataUpload = {
  //     lead_id: data[0],
  //     upload_status: data[1],
  //   };
  //   // console.log(dataUpload, "e.target");
  //   const formdata = axios
  //     .post("https://isp.taskmo.in/meesho/update_upload_status", dataUpload)
  //     .then((res) => {
  //       // console.log(res.data, "uploader status");
  //       setTimeout(() => {
  //         window.location.reload();
  //         // const display_download = document.getElementById(data[0]);
  //         // display_download.style.display = "none";
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleApprove = (data) => {
    const dataEnter = {
      id: data,
    };
    axios
      .post(client_approve_lead, dataEnter)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Approved",

          showConfirmButton: false,
          timer: 1500,
        });
        set_check(!check);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: "Lead ID",
      selector: "lead_id",
      sortable: true,
      center: true,
      cell: (d) => <div>{d.lead_id}</div>,
    },
    {
      name: "Date",
      selector: "created_on",
      sortable: true,
      center: true,
      cell: (d) => <div>{moment(d.created_on).format("DD-MM-YYYY")}</div>,
    },
    {
      name: "City",
      selector: "city",
      sortable: true,
      center: true,
      cell: (d) => <div>{d.city}</div>,
    },
    {
      name: "Merchant name",
      selector: "merchant_name",
      center: true,
      sortable: true,

      cell: (d) => <div>{d.merchant_name}</div>,
    },

    {
      name: "Merchant Number",
      selector: "merchant_number",
      cell: (d) => <div>{d.merchant_number}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "QC Status",
      selector: "qc_status",
      sortable: true,
      cell: (d) => <div className={d.qc_status}>{d.qc_status}</div>,
      center: true,
    },
  ];

  if (admin) {
    columns.splice(2, 0, {
      name: "FSE Id",
      selector: "user_id",
      cell: (d) => <div>{d.user_id}</div>,
      sortable: true,
      center: true,
    });
    columns.splice(3, 0, {
      name: "FSE Name",
      selector: "fse_name",
      cell: (d) => <div>{d.fse_name}</div>,
      sortable: true,
      center: true,
    });
    columns.splice(4, 0, {
      name: "FSE Number",
      selector: "fse_number",
      cell: (d) => <div>{d.fse_number}</div>,
      sortable: true,
      center: true,
    });
  }
  if (!admin) {
    columns.splice(9, 0, {
      name: "Action",
      selector: "legal_name",
      cell: (d) => (
        <DropdownButton
          id="dropdown-item-button"
          variant="Secondary"
          style={
            d.qc_status == "Approved"
              ? { pointer: "none" }
              : { cursor: "pointer" }
          }
        >
          {d.qc_status == "Pending" ? (
            <>
              <Dropdown.Item
                as="button"
                onClick={() => handleApprove(d.lead_id)}
              >
                Approve
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={() => {
                  set_lead_id(d.lead_id);
                  setOpen1(true);
                }}
              >
                Reject
              </Dropdown.Item>
            </>
          ) : d.qc_status == "Rejected" ? (
            <>
              <Dropdown.Item
                as="button"
                onClick={() => handleApprove(d.lead_id)}
              >
                Approve
              </Dropdown.Item>
            </>
          ) : (
            <>
              <Dropdown.Item as="button">No Action</Dropdown.Item>
            </>
          )}
        </DropdownButton>
      ),
      sortable: true,
      center: true,
    });
  }

  const tableData = {
    columns,
    data: api,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const dataEnter = {
      id: lead_id,
      remark: formData.remark,
    };
    console.log(dataEnter, "dataEnter");
    axios
      .post(client_reject_lead, dataEnter)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Rejected",

          showConfirmButton: false,
          timer: 1500,
        });
        setOpen1(false);
        set_check(!check);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleView = async (id) => {
  //   try {
  //     // console.log(id, "object");
  //     const loadData = await axios.post(
  //       "https://isp.taskmo.in/meesho/get_products",
  //       {
  //         lead_id: id,
  //       }
  //     );
  //     // console.log(loadData.data.products, "inside view");
  //     setImage(loadData.data.products);
  //     setOpen1(true);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // console.log(api, "data");
  return (
    <div className="table_data">
      <div className="card_table">
        <div className="menu-container">
          <TripleDotDropDown admin={admin} />
        </div>
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
        >
          <DataTable
            columns={columns}
            data={table_data.data}
            defaultSortFieldId={(2, "Descending")}
            pagination
            theme="solarized"
          />
        </DataTableExtensions>
      </div>
      <Modal
        open={open1}
        onClose={handleClose1}
        className="modal_call"
        center={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="h-50 w-50 bg-white p-4">
            <h3 className="mb-4">Reject Lead</h3>
            <div className="h-50 d-flex flex-column justify-content-center align-items-center mt-4">
              <p className="w-50 text-left">
                Please write the reason to reject!
              </p>
              <input
                type="text"
                name="remark"
                onChange={handleChange}
                className="w-50"
              />
            </div>
            <div className="w-75 text-right mt-4">
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Table;
