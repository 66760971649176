import React, { useEffect, useState } from "react";
// import MyDataTable from "../MyDataTable";
import "./Ninja.css";
import { Spinner } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";
import AppEchart from "../echarts/AppEchart";
import { apexNinja } from "./apexOptions";
import StatusBar from "../StatusBar/StatusBar";
import {
  leadReportAdminUrl,
  leadReportClientUrl,
  leadStats,
  tableList,
} from "../../utils";
import {
  leadStatChartOption,
  rCountChartOption,
} from "../echarts/echartOptions";
import Table from "../STable/Table";
import CityCheckinChart from "./CityCheckinChart";
import BarChart from "./BarChart";
import CityLeadRegisterChart from "./CityLeadRegisterChart";
import CityQcApprovedChart from "./CityQcApprovedChart";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Ninja({ setLoggedIn, admin }) {
  // const [productData, setProductData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [moreFocus, setMoreFocus] = useState(false);
  const [api, setApi] = useState([]);
  const [renderLeftChart, setRenderLeftChart] = useState(false);
  const [check, set_check] = React.useState(false);
  const [cardsApi, setCardsApi] = useState({});
  const [chartLeftOption, setChartLeftOption] = useState({
    color: ["#62549c", "#7566b5", "#7d6cbb", "#8877bd", "#9181bd", "#6957af"],
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0, .8)",
    },

    series: [
      {
        // name: 'Sales by Country',
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: [
          { value: 535, name: "Leads" },
          { value: 310, name: "Approved" },
          { value: 234, name: "1st Order" },
          { value: 155, name: "2nd Order" },
          { value: 130, name: "3rd Order" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });
  const [chartRightOption, setChartRightOption] = useState({});
  const handleClick = (id) => {
    var click = document.getElementById("list-items");
    if (click.style.display === "none") {
      click.style.display = "block";
    } else {
      click.style.display = "none";
    }
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9e`,
      },
    };
    const configAdmin = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-`,
      },
    };
    const configCard = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9e.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9e`,
      },
    };
    // if (!admin) {
    const finalData = axios
      .get(tableList, configAdmin)
      .then((res) => {
        setApi([...res.data.leads]);
        // console.log(res, "axios data");
      })
      .catch((err) => {
        // console.log(err);
      });
    // }

    fetch(leadStats, configCard)
      .then((r) => r.json())
      .then((r) => {
        if (r.message === "success") {
          setCardsApi({ ...r });
          chartLeftOption.series[0].data[0].value = r.total_leads;
          chartLeftOption.series[0].data[1].value = r.total_approved;
          chartLeftOption.series[0].data[2].value = r.total_first_order;
          chartLeftOption.series[0].data[3].value = r.total_first_order;
          chartLeftOption.series[0].data[4].value = r.total_first_order;
          // console.log(chartLeftOption.series[0].data);
          setChartLeftOption({ ...chartLeftOption });
          setRenderLeftChart(true);
        } else {
          console.error(r.error);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [check]);

  // useEffect(()=>{

  // },[searchText]);
  console.log(api, "something in card");
  return (
    <div
      className="ninja"
      onClick={() => {
        if (moreFocus) {
          setMoreFocus(false);
        }
      }}
    >
      {/* <button onClick={()=>{
        chartLeftOption.series[0].data[0].name="mayur";
        setChartLeftOption({...chartLeftOption});
      }}>Click</button> */}
      <div className="ninja_page">
        <div className="bijnis_row1">
          <div></div>
          <p className="n_p1" style={{ color: "#121212" }}>
            NowFloats Merchant Onboarding
          </p>
          <div
            className="logout-container"
            style={{
              backgroundImage: `url(${
                window.location.origin + "/images/Logout.svg"
              })`,
            }}
            onClick={() => {
              localStorage.removeItem("jma");
              setLoggedIn(false);
            }}
          >
            <p className="bijnis_p1">Logout</p>
          </div>
        </div>

        <div className="ninja_row1">
          <div className="nin_row2">
            <div
              className="bijnis_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/total_onboarding.svg"
                })`,
              }}
            >
              <p className="bijnis_p3">Total Leads</p>
              <p className="bijnis_p4">
                {Object.keys(cardsApi).length > 2
                  ? cardsApi.total_leads
                  : "XXXX"}
              </p>
              <p className="nin_p5">+{cardsApi.total_leads_today}</p>
            </div>
            {admin ? (
              <div
                className="bijnis_cards"
                style={{
                  backgroundImage: `url(${
                    window.location.origin + "/images/total_onboarding.svg"
                  })`,
                }}
              >
                <p className="bijnis_p3">QC Passed</p>
                <p className="bijnis_p4">
                  {Object.keys(cardsApi).length > 0
                    ? cardsApi.total_qc_passed
                    : "XXXX"}
                </p>
                <p className="nin_p5">+{cardsApi.total_qc_passed_today}</p>
              </div>
            ) : null}
            {admin ? (
              <div
                className="bijnis_cards"
                style={{
                  backgroundImage: `url(${
                    window.location.origin + "/images/total_onboarding.svg"
                  })`,
                }}
              >
                <p className="bijnis_p3">Total Checkin</p>
                <p className="bijnis_p4">
                  {Object.keys(cardsApi).length > 0
                    ? cardsApi.total_checkin
                    : "XXXX"}
                </p>
                <p className="nin_p5">+{cardsApi.total_checkin_today}</p>
              </div>
            ) : null}
            {/* <div
              className="bijnis_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/TOTAL_LEADS.svg"
                })`,
              }}
            >
              <p className="bijnis_p3">Footware Onboarding</p>
              <p className="bijnis_p4">
                {Object.keys(cardsApi).length >0
                  ? cardsApi.shoe_count
                  : "XXXX"}
              </p>
            </div> */}
            {/* <div
              className="bijnis_cards"
              
              style={{ backgroundColor: "rgb(136 119 189)" }}
            >
              <p className="bijnis_p3">Total KYC Approved</p>
              <p className="bijnis_p4">
                {Object.keys(cardsApi).length > 2
                  ? cardsApi.total_kyc_approved
                  : "XXXX"}
              </p>
              <p className="nin_p5">+120</p>
            </div> */}
            {/* <div
              className="bijnis_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/TOTAL_LEADS.svg"
                })`,
              }}
            >
              <p className="bijnis_p3">Clothing Onboarding</p>
              <p className="bijnis_p4">
                {Object.keys(cardsApi).length > 2
                  ? cardsApi.cloth_count
                  : "XXXX"}
              </p>
            </div> */}
          </div>
          <div className="charts-row">
            <div className="nin_col1">
              <p className="nin_p6">City-wise Lead Registration</p>
              <CityLeadRegisterChart />
            </div>
            <div className="nin_col1">
              <p className="nin_p6">City-wise QC Approved</p>
              <CityQcApprovedChart />
            </div>

            {admin ? (
              <>
                <div className="nin_col1">
                  <p className="nin_p6">City-wise Checkin</p>
                  {/* <ReactApexChart
                options={apexNinja.options}
                series={apexNinja.series}
                type="pie"
                width={350}
                style={{ marginTop: "25px" }}
              /> */}

                  <CityCheckinChart />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="charts-row">
            <div className={`nin_col1`} style={{ width: "100vw" }}>
              <p className="nin_p6">Lead Registration Count</p>
              {/* <ReactApexChart
                options={apexNinja.options}
                series={apexNinja.series}
                type="pie"
                width={350}
                style={{ marginTop: "25px" }}
              /> */}
              {/* {renderLeftChart ? <AppEchart option={echartBarOption} /> : <></>} */}

              <BarChart />
            </div>
          </div>

          {api.length > 0 ? (
            <>
              <div>
                {/* <MyDataTable api={api}/> */}
                {/* {localStorage.setItem(
                  "jiomart_data_table",
                  JSON.stringify({ api: api.slice(0, 1000) })
                )} */}
                <Table
                  api={api}
                  admin={admin}
                  set_check={set_check}
                  check={check}
                />
              </div>
            </>
          ) : (
            <>
              <div className="load">
                <div className="load-item">
                  <Spinner animation="grow" variant="dark" />
                </div>
                <div className="load-item">
                  <Spinner animation="grow" variant="dark" />
                </div>
                <div className="load-item">
                  <Spinner animation="grow" variant="dark" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="table_invisible">
        <ReactHTMLTableToExcel
          className="btn btn-info"
          table="emp"
          filename="ReportExcel"
          sheet="Sheet"
          buttonText="Export excel"
        />
        <table id="emp" class="table">
          <thead>
            <tr>
              <th>Lead ID</th>
              <th>Date</th>
              <th>Merchant Name</th>
              <th>Store Name</th>
              <th>Store Legal Name</th>
              <th>Contact Number</th>
              <th>City</th>
              <th>Shop category</th>
            </tr>
          </thead>
          <tbody>
            {api.map((data) => (
              <tr>
                <th>{data.lead_id}</th>
                <th>{data.activity_date}</th>
                <th>{data.merchant_name}</th>
                <th>{data.shop_name}</th>
                <th>{data.shop_legal_name}</th>
                <th>{data.merchant_number}</th>
                <th>{data.city}</th>
                <th>{data.category}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}

export default Ninja;
