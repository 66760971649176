const tableList = "https://projectsbackend.taskmo.co/nowfloats/leads/api";
const leadStats = "https://projectsbackend.taskmo.co/nowfloats/lead_stats";
const checkEmail = "https://projectsbackend.taskmo.co/nowfloats/check_email";
const leadReportAdminUrl =
  "https://projectsbackend.taskmo.co/nowfloats/download/all_leads";
const leadReportClientUrl =
  "https://projectsbackend.taskmo.co/nowfloats/download/all_leads_client";
const downloadCheckinReportUrl =
  "https://projectsbackend.taskmo.co/nowfloats/download/leads/client";
const DOWNLOAD_CHECKIN =
  "https://projectsbackend.taskmo.co/nowfloats/download/checkin";
const todayCheckinUrl =
  "https://projectsbackend.taskmo.co/nowfloats/today_checkins";
const thisWeekCheckinUrl =
  "https://projectsbackend.taskmo.co/nowfloats/thisweek_checkins";
const thisMonthCheckinUrl =
  "https://projectsbackend.taskmo.co/nowfloats/thismonth_checkins";
const TODAY_LEAD_URL =
  "https://projectsbackend.taskmo.co/nowfloats/today_leads";
const THIS_MONTH_LEAD_URL =
  "https://projectsbackend.taskmo.co/nowfloats/thismonth_leads";
const TODAY_CITY_WISE_LEAD_URL =
  "https://projectsbackend.taskmo.co/nowfloats/today_leads_city";
const THISWEEK_CITY_WISE_LEAD_URL =
  "https://projectsbackend.taskmo.co/nowfloats/thisweek_leads_city";
const THISMONTH_CITY_WISE_LEAD_URL =
  "https://projectsbackend.taskmo.co/nowfloats/thismonth_leads_city";
const TODAY_CITY_WISE_QC_LEADS =
  "https://projectsbackend.taskmo.co/nowfloats/todayApprovedLeads";
const THISWEEK_CITY_WISE_QC_LEADS =
  "https://projectsbackend.taskmo.co/nowfloats/weeklyApprovedLeads";
const THISMONTH_CITY_WISE_QC_LEADS =
  "https://projectsbackend.taskmo.co/nowfloats/monthlyApprovedLeads";
const client_approve_lead =
  "https://projectsbackend.taskmo.co/nowfloats/client_approve_lead";
const client_reject_lead =
  "https://projectsbackend.taskmo.co/nowfloats/client_reject_lead";
export {
  tableList,
  leadStats,
  checkEmail,
  leadReportAdminUrl,
  leadReportClientUrl,
  downloadCheckinReportUrl,
  todayCheckinUrl,
  thisWeekCheckinUrl,
  thisMonthCheckinUrl,
  TODAY_LEAD_URL,
  THIS_MONTH_LEAD_URL,
  TODAY_CITY_WISE_LEAD_URL,
  THISWEEK_CITY_WISE_LEAD_URL,
  THISMONTH_CITY_WISE_LEAD_URL,
  TODAY_CITY_WISE_QC_LEADS,
  THISWEEK_CITY_WISE_QC_LEADS,
  THISMONTH_CITY_WISE_QC_LEADS,
  DOWNLOAD_CHECKIN,
  client_reject_lead,
  client_approve_lead,
};
