import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import {
  leadReportAdminUrl,
  downloadCheckinReportUrl,
  DOWNLOAD_CHECKIN,
} from "../../utils";
import { getElementError } from "@testing-library/react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios, { AxiosRequestConfig } from "axios";
import { THISMONTH_CITY_WISE_LEAD_URL } from "../../utils";
import FileSaver from "file-saver";
import FileDownload from "js-file-download";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
export default function TripleDotDropDown({ admin }) {
  const jmae = localStorage.getItem("jmae");

  const inputFile = useRef(null);

  const date = new Date();

  const classes = useStyles();

  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [cityList, setCityList] = useState("");

  const [upload, setUpload] = useState("");
  const [uploadShow, setUploadShow] = useState("");
  const [formaDate, setFormData] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToData] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [productData, setProductData] = useState([]);
  const [toDate1, setToData1] = useState(
    // new Date(date.getFullYear(), date.getMonth(), 1)
    "YYYY-MM-DD"
  );

  const handleDateChange = (date) => {
    let formatted_date = moment(date).format("YYYY-MM-DD");
    setFormData(formatted_date);
    // console.log(formatted_date, date, "inside handle");
  };

  const handleDateChangeto = (date) => {
    let formatted_date = moment(date).format("YYYY-MM-DD");
    setToData(formatted_date);
    // console.log(formatted_date, date, "inside handle");
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const cityList = await axios
          .get(THISMONTH_CITY_WISE_LEAD_URL)
          .then((res) => {
            // console.log(res.data, "inside useffect city");
            setCityList(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };
    loadData();
  }, []);

  const submitHandle = (event) => {
    event.preventDefault();
  };

  const handleDownload = () => {
    const display_download = document.getElementById("form_date_picker");

    display_download.style.display = "block";
  };
  const handleDownloadLead = () => {
    const display_download = document.getElementById("form_date_picker_client");

    display_download.style.display = "block";
  };
  const handleCloseLead = () => {
    const display_download = document.getElementById("form_date_picker_client");
    // console.log("inside fandle close");
    display_download.style.display = "none";
  };
  const handleCheckin = () => {
    const display_download = document.getElementById(
      "form_date_checkin_picker"
    );

    display_download.style.display = "block";
  };
  const handleUpload = () => {
    const display_download = document.getElementById("modal_full_upload");

    display_download.style.display = "flex";
  };

  const handleClose = () => {
    const display_download = document.getElementById("form_date_picker");
    // console.log("inside fandle close");
    display_download.style.display = "none";
  };
  const handleCloseCheckin = () => {
    const display_download = document.getElementById(
      "form_date_checkin_picker"
    );
    // console.log("inside fandle close");
    display_download.style.display = "none";
  };

  const handleCloseUpload = () => {
    const display_download = document.getElementById("modal_full_upload");
    // console.log("inside fandle close");
    display_download.style.display = "none";
  };

  const handleBrowseUpload = (event) => {
    const file = event.target.files[0];
    setUploadShow(event.target.value);
    setUpload(file);
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleCity = (e) => {
    // console.log(e.target.value, "e.target.value");
    setCity(e.target.value);
  };
  const handleCategory = (e) => {
    // console.log(e.target.value, "e.target.categoty");
    setCategory(e.target.value);
  };

  // const DownloadButton = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9eeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9e`,
  //     },
  //   };
  //   const finalData = Axios.get(
  //     `https://isp.taskmo.in/meesho/download/leads/client?from=${formaDate}&to=${toDate}`,
  //     config
  //   ).then((res) => {
  //     console.log(res.data.leads, "on click handle");
  //     setProductData(res.data.leads);
  //   });
  // };

  const handleDateChangeto1 = (date) => {
    let formatted_date = moment(date).format("YYYY-MM-DD");
    setToData1(formatted_date);
    const config = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9eeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJpYXQiOjE2MDcxNTEwOTF9.ioWcBfTlcUXK-80EEL_TaYDi24xEdT4aE1x0VZ1tU5373893AERWYDJ3U9hdiidjodj9eu9e`,
      },
    };
    const finalData = Axios.get(
      `${downloadCheckinReportUrl}?from=${formaDate}&to=${formatted_date}`,
      config
    )
      .then((res) => {
        console.log(res.data.leads, "on click handle");
        setProductData(res.data.leads);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log(jmae, "email object");
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu size="sm" title="">
          {/* <Dropdown.Header>Options</Dropdown.Header>  {admin ? leadReportAdminUrl : leadReportClientUrl}  href={downloadCheckinReportUrl}*/}
          {/* {jmae === "vishnu.ananthan@taskmo.com" || jmae === "shreyas.r@taskmo.com" ||jmae === "harish.kr@taskmo.com" || jmae === "rakesh.km@taskmo.com" ? ( */}
          {admin ? (
            <Dropdown.Item onClick={handleDownload}>
              Download Lead report
              <img
                src={window.location.origin + "/images/download.svg"}
                alt="download"
                className="drop_image_download"
              />
            </Dropdown.Item>
          ) : null}
          {admin ? (
            <Dropdown.Item onClick={handleCheckin}>
              Download Check-In report
              <img
                src={window.location.origin + "/images/download.svg"}
                alt="download"
                className="drop_image_download"
              />
            </Dropdown.Item>
          ) : null}

          {!admin ? (
            <Dropdown.Item onClick={handleDownloadLead}>
              Download Lead report
              <img
                src={window.location.origin + "/images/download.svg"}
                alt="download"
                className="drop_image_download"
              />
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
        <div
          size="sm"
          title=""
          id="form_date_picker"
          style={{ display: "none" }}
        >
          <form onSubmit={submitHandle}>
            <div className="title_download">
              <p className="down_title_p1">Download Lead report</p>
              <img
                src={window.location.origin + "/images/close.svg"}
                alt="download"
                className="close_button"
                onClick={handleClose}
              />
            </div>
            {/* <div className="subtitle_download">Fill the below details</div> */}
            <div className="input_row_download">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  value={formaDate}
                  className={classes.textField}
                  onChange={handleDateChange}
                />
                <KeyboardDatePicker
                  label="to"
                  value={toDate}
                  className={classes.textField}
                  onChange={handleDateChangeto}
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* <div className="city_row_download">
                  <p className="download_p1">city</p>
                  <select
                    className="select_download"
                    name="city"
                    onChange={handleCity}
                    required
                  >
                    <option value="" selected disabled>
                      city
                    </option>
                    {cityList &&
                      cityList.map((cit) => <option>{cit.name}</option>)}
                  </select>
                </div> */}
            {/* <div className="city_row_download">
                  <p className="download_p1"> category</p>
                  <select
                    className="select_download"
                    name="category"
                    onChange={handleCategory}
                    required
                  >
                    <option value="" selected disabled>
                      Category
                    </option>
                    <option value="Auto">Auto Ride</option>
                    <option value="Moto">Moto Ride</option>
                  </select>
                </div> */}
            {/*onClick={DownloadButton}  UBER_DOWNLOAD_REPORT*/}
            <div className="flex_download">
              <div
                className="button_download"
                // onclick="window.open('https://w3docs.com','mywindow');"
              >
                <a
                  href={`${leadReportAdminUrl}?from=${formaDate}&to=${toDate}`}
                  target="_blank"
                >
                  <img
                    src={window.location.origin + "/images/download.svg"}
                    alt="download"
                    className="drop_image_download"
                  />
                  Download
                </a>
              </div>
            </div>
          </form>
        </div>
        <div
          size="sm"
          title=""
          id="form_date_picker_client"
          style={{ display: "none" }}
        >
          <form onSubmit={submitHandle}>
            <div className="title_download">
              <p className="down_title_p1">Download Lead report</p>
              <img
                src={window.location.origin + "/images/close.svg"}
                alt="download"
                className="close_button"
                onClick={handleCloseLead}
              />
            </div>
            {/* <div className="subtitle_download">Fill the below details</div> */}
            <div className="input_row_download">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  value={formaDate}
                  className={classes.textField}
                  onChange={handleDateChange}
                />
                <KeyboardDatePicker
                  label="to"
                  value={toDate1}
                  className={classes.textField}
                  onChange={handleDateChangeto1}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="flex_download">
              <ReactHTMLTableToExcel
                className="btn btn-info"
                table="emp"
                filename="ReportExcel"
                sheet="Sheet"
                buttonText="Download"
              />
            </div>
          </form>
        </div>
        <div
          size="sm"
          title=""
          id="form_date_checkin_picker"
          style={{ display: "none" }}
        >
          <div className="title_download">
            <p className="down_title_p1">Download Checkin report</p>
            <img
              src={window.location.origin + "/images/close.svg"}
              alt="download"
              className="close_button"
              onClick={handleCloseCheckin}
            />
          </div>
          {/* <div className="subtitle_download">Fill the below details</div> */}
          <div className="input_row_download">
            <form className={classes.container} noValidate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  value={formaDate}
                  className={classes.textField}
                  onChange={handleDateChange}
                />
                <KeyboardDatePicker
                  label="to"
                  value={toDate}
                  className={classes.textField}
                  onChange={handleDateChangeto}
                />
              </MuiPickersUtilsProvider>
            </form>
          </div>
          {/* <div className="city_row_download">
                <p className="download_p1">city</p>
                <select
                  className="select_download"
                  name="city"
                  onChange={handleCity}
                  required
                >
                  <option value="" selected disabled>
                    city
                  </option>
                  {cityList &&
                    cityList.map((cit) => <option>{cit.name}</option>)}
                </select>
              </div> */}
          {/* <div className="city_row_download">
                <p className="download_p1"> category</p>
                <select
                  className="select_download"
                  name="category"
                  onChange={handleCategory}
                  required
                >
                  <option value="" selected disabled>
                    Category
                  </option>
                  <option value="Auto">Auto Ride</option>
                  <option value="Moto">Moto Ride</option>
                </select>
              </div> */}
          <div className="flex_download">
            <div className="button_download">
              <a
                href={`${DOWNLOAD_CHECKIN}?from=${formaDate}&to=${toDate}`}
                target="_blank"
              >
                <img
                  src={window.location.origin + "/images/download.svg"}
                  alt="download"
                  className="drop_image_download"
                />
                Download
              </a>
            </div>

            {/* <div
                  className="button_download1"
                >
                  <a href={`https://isp.taskmo.in/fse/uber_bike/download/checkin?from=${formaDate}&to=${toDate}`} target="_blank">
                    <img
                      src={window.location.origin + "/images/download.svg"}
                      alt="download"
                      className="drop_image_download"
                    />
                    Download Moto
                  </a>
                </div> */}
          </div>
        </div>
        <div
          className="modal_upload"
          id="modal_full_upload"
          style={{ display: "none" }}
        >
          <div className="modal_card_upload">
            <img
              src={window.location.origin + "/images/close.svg"}
              alt="download"
              className="close_button_upload"
              onClick={handleCloseUpload}
            />
            <div className="modal_row_upload">
              <img
                src={window.location.origin + "/images/document.svg"}
                alt="document"
                className="document_upload_image"
              />
              <p className="modal_text_p1">Download the report file </p>
              <div
                className="button_download_modal"
                // href={downloadCheckinReportUrl}
              >
                <img
                  src={window.location.origin + "/images/download.svg"}
                  alt="download"
                  className="drop_image_download"
                />
                Download
              </div>
              <p className="modal_text_p4">
                Choose a file to upload the document
              </p>
              <input
                type="file"
                ref={inputFile}
                id="file"
                style={{ display: "none" }}
                onChange={handleBrowseUpload}
              />
              <p className="modal_text_p2">
                Click here to
                <span>
                  <a className="click_browse" onClick={onButtonClick}>
                    Browse
                  </a>
                </span>
              </p>
              {uploadShow.length === 0 ? (
                <></>
              ) : (
                <p className="model_text_p3">{uploadShow}</p>
              )}
              <div
                className="button_download_modal"
                // href={downloadCheckinReportUrl}
              >
                <img
                  src={window.location.origin + "/images/upload.svg"}
                  alt="download"
                  className="drop_image_download"
                />
                Upload
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
      <div className="table_invisible" style={{ display: "none" }}>
        <table id="emp" class="table">
          <thead>
            <tr>
              <th>Lead ID</th>
              <th>Date</th>
              <th>State</th>
              <th>City</th>
              <th>Merchant Email Id</th>
              <th>Category</th>
              <th>Shop Name</th>
              <th>Shop Legal Name</th>
              <th>Merchant Name</th>
              <th>Merchant Number</th>
              <th>Aadhar Front Image</th>
              <th>Aadhar Back Image</th>
              <th>Shop Inside Image</th>
              <th>Shop outside Image</th>
              <th>GSTIN Image</th>
              <th>Registration Image</th>
              <th>GSTIN Number</th>
              <th>Lat Long</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((data) => (
              <tr>
                <th>{data.lead_id}</th>
                <th>{data.activity_date}</th>
                <th>{data.lead_state}</th>
                <th>{data.lead_city}</th>
                <th>{data.merchant_email_id}</th>
                <th>{data.category}</th>
                <th>{data.shop_name}</th>
                <th>{data.shop_legal_name}</th>
                <th>{data.merchant_name}</th>
                <th>{data.merchant_number}</th>
                <th>{data.aadhar_front_image}</th>
                <th>{data.aadhar_back_image}</th>
                <th>{data.shop_inside_image}</th>
                <th>{data.shop_outside_image}</th>
                <th>{data.registred_gstn_image}</th>
                <th>{data.registration_image}</th>
                <th>{data.gst_number}</th>
                <th>{data.latlong}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
//https://stackoverflow.com/questions/60123638/use-three-dots-span-to-trigger-dropdown/60127027#60127027
