const table_data = {
  data: [
    {
      lead_id: 4832,
      user_id: 2622,
      sow_id: 17,
      ref_table_id: 1701,
      ref_table_name: "rapido_leads",
      qc_status: "0",
      stage1_status: "Pending",
      stage2_status: "Pending",
      stage3_status: "Pending",
      stage4_status: "0",
      stage1_reason: "",
      stage2_reason: "",
      stage3_reason: "",
      stage4_reason: "",
      qc_remark: "",
      is_active: "1",
      s1_created_on: "2021-07-06T09:40:40.000Z",
      s2_created_on: "0000-00-00 00:00:00.000000",
      s3_created_on: "0000-00-00 00:00:00.000000",
      s4_created_on: "0000-00-00 00:00:00.000000",
      created_on: "2021-07-06T09:40:40.000Z",
      updated_on: "0000-00-00 00:00:00.000000",
      fse_name: "yogeshs",
      fse_number: "7259740405",
      driver_name: "venkatesh",
      driver_contact: "9731319770",
      vehicle_number: "KA02AE0064",
      area: "7th Cross Road",
      activity_date: "06/07/2021",
      ride1_date: "0",
      ride2_date: "0",
      ride3_date: "0",
      ride4_date: "0",
      ride5_date: "0",
      qc_by: "NA",
      city: "Bengaluru",
    },
    {
      lead_id: 4831,
      user_id: 1773,
      sow_id: 17,
      ref_table_id: 1700,
      ref_table_name: "rapido_leads",
      qc_status: "0",
      stage1_status: "Pending",
      stage2_status: "Pending",
      stage3_status: "Pending",
      stage4_status: "0",
      stage1_reason: "",
      stage2_reason: "",
      stage3_reason: "",
      stage4_reason: "",
      qc_remark: "",
      is_active: "1",
      s1_created_on: "2021-07-06T09:40:24.000Z",
      s2_created_on: "0000-00-00 00:00:00.000000",
      s3_created_on: "0000-00-00 00:00:00.000000",
      s4_created_on: "0000-00-00 00:00:00.000000",
      created_on: "2021-07-06T09:40:24.000Z",
      updated_on: "0000-00-00 00:00:00.000000",
      fse_name: "Salman Khan B",
      fse_number: "9738235668",
      driver_name: "Ambrut A",
      driver_contact: "7337814978",
      vehicle_number: "KA02AH5367",
      area: null,
      activity_date: "06/07/2021",
      ride1_date: "0",
      ride2_date: "0",
      ride3_date: "0",
      ride4_date: "0",
      ride5_date: "0",
      qc_by: "NA",
      city: "Bengaluru",
    },
    {
      lead_id: 4830,
      user_id: 2942,
      sow_id: 17,
      ref_table_id: 1699,
      ref_table_name: "rapido_leads",
      qc_status: "0",
      stage1_status: "Pending",
      stage2_status: "Pending",
      stage3_status: "Pending",
      stage4_status: "0",
      stage1_reason: "",
      stage2_reason: "",
      stage3_reason: "",
      stage4_reason: "",
      qc_remark: "",
      is_active: "1",
      s1_created_on: "2021-07-06T09:39:28.000Z",
      s2_created_on: "0000-00-00 00:00:00.000000",
      s3_created_on: "0000-00-00 00:00:00.000000",
      s4_created_on: "0000-00-00 00:00:00.000000",
      created_on: "2021-07-06T09:39:28.000Z",
      updated_on: "0000-00-00 00:00:00.000000",
      fse_name: "Rajanna YP",
      fse_number: "9945181716",
      driver_name: "Ashwathaiah L M",
      driver_contact: "9945193974",
      vehicle_number: "KA02AC8855",
      area: "24th Main Road",
      activity_date: "06/07/2021",
      ride1_date: "0",
      ride2_date: "0",
      ride3_date: "0",
      ride4_date: "0",
      ride5_date: "0",
      qc_by: "NA",
      city: "Bengaluru",
    },
  ],
};

export { table_data };
